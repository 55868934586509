import * as React from "react";
import { useState } from "react"
import { ISlide } from "../../types/contentfulTypes";
import * as styles from './singleSlide.module.scss';
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import {NAME} from "../../consts/common"

type Props = {
  slide: ISlide;
}

const SingleSlide: React.FC<Props> = ({
  slide: {
    slideTitle,
    slideImage: { file: { url }},
    slideDescription
  },
}: Props) => {
  const windowInnerHeight = typeof window !== 'undefined' && window.innerHeight;
  const [slideHeight, setSlideHeight] = useState(windowInnerHeight);

  if (typeof window !== 'undefined') {
    window.addEventListener('resize', () => {
      setSlideHeight(window.innerHeight);
    })
  }

  return (
    <div
      className={styles.slideContainer}
      key={slideTitle}
      style={{
        backgroundImage: `url(${url})`,
        height: `${slideHeight ? `${slideHeight}px` : '100vh'}`
      }}
    >
      <div className={styles.textContainer}>
        <h1 className={styles.slideTitle}>{NAME} {slideTitle}</h1>
        <div
          className={styles.slideDescription}
          dangerouslySetInnerHTML={{ __html: documentToHtmlString(JSON.parse(slideDescription.raw)) }}
        />
      </div>
    </div>
  )
}

export default SingleSlide;
