import * as React from 'react';
import { Component } from 'react';
import Slider from 'react-slick';
import SlidesStepper from '../SlidesStepper/SlidesStepper'
import * as styles from './slidesContainer.module.scss'

type Props = {
  children: React.ReactNode;
  numberOfSlides: number;
  slidesTitles: string[];
}

type State = {
  activeSlide: number;
}

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 10000,
  arrows: false,
};

class SlidesContainer extends Component<Props, State> {
  state = {
    activeSlide: 0,
  }
  windowHeight = typeof window !== 'undefined' && window.innerHeight;
  maxYOffset = (this.props.numberOfSlides - 1) * this.windowHeight;
  slidesContainer = React.createRef<Slider>();


  setActiveSlideIndex = (index: number) => {
    this.setState({
      activeSlide: index,
    })
  }

  setActiveSlide = (index: number) => {
    this.slidesContainer.current.slickGoTo(index);
  }

  render() {
    const { activeSlide } = this.state;
    return (
      <div className={styles.slidesContainer}>
        <SlidesStepper activeSlide={activeSlide} items={this.props.slidesTitles} onChangeSlide={this.setActiveSlide} />
        <Slider
          ref={this.slidesContainer}
          {...sliderSettings}
          afterChange={(index) => this.setActiveSlideIndex(index)}
        >{this.props.children}</Slider>
      </div>
    )
  }
}

export default SlidesContainer;
