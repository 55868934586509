import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout/Layout";
import { ISlide, ISlidesSet } from "../types/contentfulTypes";
import '../styles/index.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-magic-slider-dots/dist/magic-dots.css";
import SingleSlide from "../components/SingleSlide/SingleSlide"
import SlidesContainer from "../components/SlidesContainer/SlidesContainer"

type Props = {
  data: {
    contentfulSlidesSet: ISlidesSet
  }
}

// TODO: Is it possible to pass the slides set's identifier instead of hardcoding it?
export const query = graphql`
query GetSlides(
  $locale: String
) {
  contentfulSlidesSet(
    identifier: {
      eq: "main-menu"
    },
    slides: {
      elemMatch: {
        node_locale: {
          eq: $locale
        }
      }
    }
  ) {
    slides {
      slideTitle
      slideImage {
        file {
          url
        }
      }
      slideDescription {
        raw
      }
    }
  }
}
`

const Home: React.FC<Props> = ({ data: { contentfulSlidesSet } }) => {
  const slides: ISlide[] = contentfulSlidesSet.slides;
  const slidesTitles = slides.map(i => i.slideTitle)

  return (
    <Layout imagesToPreload={slides && slides.map(slide => slide.slideImage.file.url)}>
      <SlidesContainer numberOfSlides={slides.length} slidesTitles={slidesTitles}>
        {slides.map((slide: ISlide, index) => <SingleSlide key={index} slide={slide} />)}
      </SlidesContainer>
    </Layout>
  )
}

export default Home;
