import * as React from "react";
import * as styles from './slidesStepper.module.scss';

type Props = {
  activeSlide: number,
  items: string[],
  onChangeSlide: (index: number) => void;
}

const SlidesStepper: React.FC<Props> = ({ activeSlide, items, onChangeSlide }: Props) => (
  <div className={styles.slidesStepper}>
    {items.map((item, index) => {
      return (
        <div
          className={`${activeSlide === index ? styles.stepperItem__active : styles.stepperItem__base}`}
          onClick={() => {onChangeSlide(index)}}
          key={item}
        >
          <div>{item}</div>
        </div>
      )
    })}
  </div>
)

export default SlidesStepper
